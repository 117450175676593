import Servatom from '../../assets/Servatom.png';
import styles from './Footer.module.css';

const Footer = () => {
    return(
        <div className={styles.footer}>
            <p>Made with ❤︎ at </p>
            <a href='https://servatom.com'><img src={Servatom}/></a>
        </div>
    )
}

export default Footer