import Nav from '../Nav/Nav';
import styles from './Landing.module.css';
import {BsChevronCompactDown} from 'react-icons/bs';
import blob1 from '../../assets/blobs/blob1.png';
import blob2 from '../../assets/blobs/blob2.png';
import blob3 from '../../assets/blobs/blob3.png';
import blob4 from '../../assets/blobs/blob4.png';
import ill1 from '../../assets/landing/step1.png';
import ill2 from '../../assets/landing/step2.png';
import ill3 from '../../assets/landing/step3.png';
import ill4 from '../../assets/landing/step4.png';

const Landing = () =>{
    return(
        <>
            <div className={styles.landing}>
                <Nav/>
                <div className={styles.title}>
                    <h1>
                        The <span>digital marksheet</span> for your event.
                    </h1>
                    <div className={styles.scroll}>
                        <p>Here's how it works</p>
                        <BsChevronCompactDown size={30} className={styles.arrow}/>
                    </div>
                    <img src={blob1} className={styles.blob1}/>
                    <img src={blob2} className={styles.blob2}/>
                    <img src={blob3} className={styles.blob3}/>
                    <img src={blob4} className={styles.blob4}/>
                </div>
            </div>
            <div className={styles.steps}>
                <img src={ill1}/>
                <div className={styles.stepText}>
                    <div className={styles.number}>1</div>
                    <h2>Create an Event and add Judges</h2>
                </div>
                <div className={styles.stepText}>
                    <h2>Add fields to the marksheet and import teams</h2>
                    <div className={styles.number}>2</div>
                </div>
                <img src={ill2}/>
                <img src={ill3}/>
                <div className={styles.stepText}>
                    <div className={styles.number}>3</div>
                    <h2>Let the judges evaluate the teams based on their submissions</h2>
                </div>
                <div className={styles.stepText}>
                    <h2>Generate the results with a click!</h2>
                    <div className={styles.number}>4</div>
                </div>
                <img src={ill4}/>
            </div>
            <div className={styles.cta}>
                <h2>It's really <span>that</span> simple.</h2>
                <div>
                    <a>Create an Event</a>
                    <div className={styles.underline}></div>
                </div>
            </div>
        </>
    )
}

export default Landing;