import Footer from "./components/Footer/Footer";
import Landing from "./components/Landing/Landing";
import Nav from "./components/Nav/Nav";

function App() {
    return (
        <>
            <Landing/>
            <Footer/>
        </>
    );
    }

    export default App;
    
