import styles from './Nav.module.css';

const Nav =() =>{
    return(
        <div className={styles.nav}>
            <h2 className={styles.logoName}>
                Gradeyard
            </h2>
            <div className={`${styles.linksGroup} ${styles.centreLinks}`}>
                <a>About</a>
                <a>Blog</a>
            </div>
            <div className={styles.linksGroup}>
                <a>Be a judge</a>
                <a>Go to Dashboard</a>
            </div>
        </div>
    )
}

export default Nav;